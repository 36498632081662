<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab
        :disabled="formData.id_service_statuses !== 1"
      >
        <template #title>
          <feather-icon icon="BoxIcon" />
          <span>Makine</span>
        </template>
        <machine-search />
      </b-tab>
      <b-tab
        :disabled="!formData.id_machines || formData.id_service_statuses !== 1"
      >
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Servis</span>
        </template>
        <service-form1 v-if="formData.formType === '1'" />
        <service-form2 v-if="formData.formType === '2'" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ImageIcon" />
          <span>Fotoğraf</span>
        </template>
        <photo-upload />
      </b-tab>
      <b-tab
        active
        :disabled="!formData.id_machines"
      >
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>Önizleme & Kayıt</span>
        </template>
        <b-card>
          <b-card-title>Müşteri Kartı</b-card-title>
          <b-card-text>
            <customer-card />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Servis Bakım</b-card-title>
          <b-card-text>
            <service-card
              :service-data="formData"
              :form-type="formData.formType"
            />
            <hr>
            <div class="mt-2">
              <b-form-group>
                <b-form-checkbox
                  v-model="sendApproval"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="text-danger">
                    Yetkili Onayına Gönderilsin
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <b-alert
                v-if="formData.id_service_statuses !== 1"
                show
                variant="warning"
              >
                <div class="alert-body text-center">
                  <FeatherIcon icon="InfoIcon" />
                  Servis formu onay yetkisine gönderilecektir. Bu işlem geri alınamaz.
                </div>
              </b-alert>
            </div>
          </b-card-text>
        </b-card>
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="submitStatus || saveStatus.status"
            @click="submitForm"
          >
            <FeatherIcon icon="CheckCircleIcon" />
            {{ (formData.id_service_statuses !== 1)? 'Onaya Gönder' : 'Kaydet' }}
          </b-button>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal
      id="qrcode-modal"
      centered
      title="QR Kod Okuyucu"
      size="lg"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        QR Kod Okuyucu Buraya Gelecek.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BFormGroup,
  BButton,
  BTabs,
  BTab,
  BModal,
  BFormCheckbox,
  BAlert,
} from 'bootstrap-vue'
import customerCard from '@/views/Services/Components/CustomerCard.vue'
import machineCard from '@/views/Services/Components/MachineCard.vue'
import serviceCard from '@/views/Services/Components/ServiceCard.vue'
import MachineSearch from '@/views/Services/Components/MachineSearch.vue'
import ServiceForm1 from '@/views/Services/Forms/ServiceForm1.vue'
import ServiceForm2 from '@/views/Services/Forms/ServiceForm2.vue'
import PhotoUpload from '@/views/Services/Forms/PhotoUpload.vue'

export default {
  name: 'Edit',
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BButton,
    BTabs,
    BTab,
    BModal,
    BFormCheckbox,
    BAlert,
    customerCard,
    machineCard,
    serviceCard,
    MachineSearch,
    ServiceForm1,
    ServiceForm2,
    PhotoUpload,
  },
  data() {
    return {
      submitStatus: false,
      sendApproval: false,
    }
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    sendApproval(val) {
      if (this.formData.id_service_statuses !== 3) {
        if (val === true) {
          this.formData.id_service_statuses = 2
        } else {
          this.formData.id_service_statuses = 1
        }
      }
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getService()
  },
  methods: {
    getService() {
      this.$store.dispatch('services/serviceView', this.$route.params.id)
        .then(res => {
          if (res.id_machines) {
            this.getMachine(res.id_machines)
            this.getPhotos(res.id)
          }
        })
    },
    getPhotos(id) {
      this.$store.dispatch('servicePhotos/getDataList', { id })
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
        .then(res => {
          this.machineSelected = true
          this.formData.id_machines = res.id
          this.formData.id_customers = res.id_customers
          this.formData.id_customer_address = res.id_customer_address
          this.getCustomer(res.id_customers)
          this.getCustomerAddress(res.id_customer_address)
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    // setFormData(data) {
    //   const serviceData = data
    //   serviceData.id = Number(serviceData.id)
    //   serviceData.id_service_statuses = Number(serviceData.id_service_statuses)
    //   serviceData.general_situation = Number(serviceData.general_situation)
    //   this.formData = serviceData
    //   this.getMachine(serviceData.id_machines)
    //   this.machineSelected = true
    //   this.changeUsedOils()
    // },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
